import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons/faArrowRightLong"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Container, Section } from "../components/Containers"

const NotFoundPage = () => (
  <Layout language="en">
    <SEO title="404 Error" description="404 page not found." robots="noindex" />

    <Section>
      <Container xsmall>
        <div className=" page-not-found">
          <h1>404: Page not found</h1>
          <p>
            It looks like the page you are looking for is missing or no longer
            exists. Please check the URL to make sure it’s correct, or go back
            to the homepage.
          </p>
          <Link className="mt-3 arrow-link" to="/" title="Return to homepage">
            Home
            <FontAwesomeIcon icon={faArrowRightLong} />
          </Link>
        </div>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
